.App,
html {
  overflow-x: hidden;
  scrollbar-width: none;
  font-family: "Outfit";
  /* cursor: none !important; */
}


html {
  scroll-behavior: smooth;
}


::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  display: none;
  visibility: hidden;
}


body {
  background-image: url('../src/Components/assets/BG.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

button,
img,
a {
  cursor: pointer;
}


@font-face {
  font-family: "BuiltTitling";
  src: local("BuiltTitling"),
    url("../src/Components/assets/fonts/built_titling/built\ titling\ lt.otf") format("opentype");
  font-weight: 400;
}


input:focus,
input.valid,
input.active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill,
input:-webkit-autofill:active {
  outline: 0;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;

}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
}


/* 
@media screen and (max-width:768px) {

  .custom-cursor {
    display: none;
  }

} */