.EP-container {
    display: flex;
    height: 100vh;
    margin: 115px 80px 0 140px;

}

.EP-container-left {
    /* margin: 115px 0 0 80px; */
    width: 500px;
}


.EP-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 64px;
    font-family: "BuiltTitling";
    margin-bottom: -5px;
    width: 400px;
    font-weight: 600;
    letter-spacing: 2.2px
}

.EP-btn {
    width: 182px;
}

.EP-content {
    font-family: "Outfit";
    color: #999999;
    margin: 24px 0 40px 0;
}

.EP-container-right .EP-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.event-portfolio-btn-container {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 42px;
}




.EPcardContainer {
    display: flex;
    align-items: center;
    margin-top: 50px;

}

.EC1 {

    z-index: 3;


}

.EC2 {
    margin: 0px 0 0px -347px;
    z-index: 2;

}

.EC3 {
    margin: 0px 0 0px -304px;
    z-index: 1;

}




.EP-content {
    color: #999999;
    font-size: 18px;
}



@media screen and (max-width:768px) {


    .EP-container{
        display: block;
        margin: 0 24px;
    }

    .EP-container-left {
        width: 100%;
        margin: 0;
    }

    .EP-content{
        font-size: 14px;
        margin: 24px 0;
        width: 312px;
    }

    .EP-title{
        font-size: 34px;
    }
    
    .EP-container-right{
        display: flex;
        flex-direction: column-reverse;
    }

    .EPcardContainer img{
        width: 225px;
    }
    .EPcardContainer {
      
        justify-content: center;
    }

    .EC2 {
        margin: 0px 0 0px -190px;
    }

    .EC3 {
        margin: 0px 0 0px -125px;
    }
    .EC1 {
        margin-left: -122px;
    }

    .event-portfolio-btn-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        margin-top: 20px;
    }

    .event-portfolio-btn-container img {

        transform: rotate(272deg);
    }

    .EP-container-right .EP-btn{
        margin-top: 27px;
    }

}


@media screen and (min-width:769px) and (max-width:1023px) {
    .EP-container {
        flex-direction: column;
        justify-content: center;
        margin: 115px 80px 0 80px;
    }

    .EP-title {
      
        font-size: 34px;
      
    }
    .EP-content {
        font-size: 14px;
        margin: 24px 0;
        width: 312px;
    }

    .EP-container-right{
        display: flex;
        flex-direction: column-reverse;
      align-items: center;
    }

    .event-portfolio-btn-container img {
        transform: rotate(272deg);
    }

    .event-portfolio-btn-container {
        align-items: center;
        gap: 0px;
        flex-direction: column;
    }

    .EPcardContainer img {
        width: 400px;
    }

    .EC1{
        margin-left: -107px;
    }
}


@media screen and (min-width:1024px) and (max-width:1439px) {
  

   
    .EP-content {
        margin: 24px 0;
        width: 312px;
    }
    .EP-container {
    
        justify-content: center;
    }
  

   

   

    .EPcardContainer img {
        width: 400px;
    }

    .EC1{
        margin-left: -107px;
    }
}


@media screen and (min-width:2000px) {

    .EP-container {
    
        justify-content: center;
    }
    
    }