.membership-container {
    display: flex;
    height: 100vh;
    justify-content: left;
    margin: 0px 0 0 140px;

}

.membership-container-left {
    width: 500px;
}


.membership-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 64px;
    font-family: "BuiltTitling";
    margin-bottom: -5px;
    font-weight: 600;
    letter-spacing: 2.2px
}

.membership-btn {
    width: 182px;
}

.membership-content {
    font-family: "Outfit";
    color: #999999;
    margin: 24px 0;
}

.membership-content-title {
    font-family: "Outfit";
    color: #999999;
    margin: 24px 0;
}

.coming-soon-arc {
    margin-top: 21px;
}



.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.OGImg,
.XRImg,
.IIImg {
    margin: 331px 0 0px 120px;

}

.OGImg {
    z-index: 3;


}

.XRImg {
    z-index: 2;
    margin-top: -331px;
    margin-left: -67px
}

.IIImg {
    z-index: 1;
    margin-top: -292px;
    margin-left: -266.3px
}

.IgnitionContainer {
    display: flex;
    align-items: center;
    margin: 47px 0px 0 -281px;
    gap: 10px;


}

.XceleratorContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 34px 0px 0 -104px;


}

.OriginalContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 85px 0px 0 18px;
}


.membership-content {
    color: #999999;
    font-size: 18px;
}


/*  */

.membership-container.blurred {
    filter: blur(5px);
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* overflow: scroll; */
}

.popup {
    margin-top: 58px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 80%;
    overflow-x: auto;
    white-space: nowrap;
}

.close-btn {

    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    margin-bottom: 20px;

}

.membership-popup {
    text-align: end;
}

/* Mobile screen */
@media screen and (max-width:768px) {

    .membership-container {
        display: block;
        margin: 24px;
    }

    .coming-soon-arc {
        margin-top: 0px;
        width: 17px;
        height: 6px;
    }

    .membership-container-left {
        width: fit-content;
    }

    .membership-title {
        font-size: 34px;
    }

    .membership-content {
        font-size: 10px;
    }

    .membership-content-title {
        font-size: 14px;
        width: 312px;
    }

    .cardContainer img {
        width: 182px;
        height: 112px;

    }

    .XRImg {
        margin-top: -175px;
        margin-left: 0px;

    }

    .IIImg {
        margin-top: -157px;
        margin-left: -146.3px;
    }

    .OGImg {
        margin: 252px 0 0px 120px
    }


    .IgnitionContainer {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: -199px 0px 0 -228px;
        gap: 0;

    }

    .XceleratorContainer {
        /* flex-direction: column-reverse; */
        align-items: center;
        gap: 5px;
        margin: 11px 0px 0 204px;
    }

    .OriginalContainer {
        margin: 203px 0 0 194px;
        align-items: flex-start;
    }



    .OriginalContainer div:first-child img,
    .XceleratorContainer div:first-child img,
    .IgnitionContainer div:first-child img {
        width: 17px;
        height: 6px;
    }

    .OriginalContainer div:first-child img {
        transform: rotate(223deg);

    }

    .IgnitionContainer div:first-child img {
        transform: rotate(241deg);
        margin-left: 48px;

    }

    .XceleratorContainer div:first-child img {
        transform: rotate(306deg);
        margin-left: -58px;

    }




    .OriginalContainer div:nth-child(2) img {
        width: 92px;
        height: 12px;
    }

    .XceleratorContainer div:nth-child(2) img {
        width: 72px;
        height: 12px;
    }

    .IgnitionContainer div:nth-child(2) img {
        width: 94px;
        height: 12px;
    }

    .membership-content {
        margin: 5px 0;
    }

    .membership-container-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .membership-btn {
        width: 182px;
        margin-top: 24px;
    }

    .popup {
        max-width: 100%;
    }

    .close-btn {
        margin-right: 23px;
        margin-top: 10px;

    }
}

/* Tablet */

@media screen and (min-width:769px) and (max-width:1024px) {

    .membership-container {
        flex-direction: column;
        margin: 0px 0 0 80px;
    }

    .membership-container-left {
        width: fit-content;
    }

    .membership-title {
        font-size: 34px;
    }

    .membership-content {
        font-size: 10px;
    }

    .membership-content-title {
        font-size: 14px;
        width: 312px;
    }

    .coming-soon-arc {
        margin-top: 23px;
        width: 17px;
        height: 6px;
    }

    .cardContainer img {
        width: 182px;
        height: 112px;

    }

    .XRImg {
        margin-top: -175px;
        margin-left: 0px;

    }

    .IIImg {
        margin-top: -157px;
        margin-left: -146.3px;
    }

    .OGImg {
        margin: 252px 0 0px 120px
    }


    .IgnitionContainer {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: -199px 0px 0 -228px;
        gap: 0;

    }

    .XceleratorContainer {
        align-items: baseline;
        gap: 5px;
        margin: -7px 0px 0 198px;
    }

    .OriginalContainer {
        margin: 224px 0 0 194px;
        align-items: flex-start;
    }



    .OriginalContainer div:first-child img,
    .XceleratorContainer div:first-child img,
    .IgnitionContainer div:first-child img {
        width: 17px;
        height: 6px;
    }

    .OriginalContainer div:first-child img {
        transform: rotate(223deg);

    }

    .IgnitionContainer div:first-child img {
        transform: rotate(241deg);
        margin-left: 48px;

    }

    .XceleratorContainer div:first-child img {
        transform: rotate(306deg);
        margin-left: -58px;

    }




    .OriginalContainer div:nth-child(2) img {
        width: 92px;
        height: 12px;
    }

    .XceleratorContainer div:nth-child(2) img {
        width: 72px;
        height: 12px;
    }

    .IgnitionContainer div:nth-child(2) img {
        width: 94px;
        height: 12px;
    }

    .membership-content {
        margin: 5px 0;
    }

    .membership-container-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .membership-btn {
        width: 182px;
        margin-top: 24px;
    }

    .popup {
        max-width: 100%;
    }

    .close-btn {
        margin-right: 35px;
        margin-top: 10px;

    }
}

/* Laptop */

@media screen and (min-width:1025px) and (max-width:1439px) {

    .membership-container {
        margin: 0 80px 0 130px;
        justify-content: center;
    }

    .membership-container-left {
        width: fit-content;
        margin: 0;
    }


    .membership-content-title {
        width: 312px;
    }

    .cardContainer img {
        width: 250px;
    }

    .XRImg {
        margin: -226px 0px -0px -29px;

    }

    .IIImg {
        margin-top: -214px;
        margin-left: -221.3px;
    }

    .OGImg {
        margin: 277px 0 0px 120px;
    }


    .IgnitionContainer {
        margin: 101px 0px 277px -504.1px;

    }

    .XceleratorContainer {
        align-items: center;
        margin: -210px 0px 343px -161px;
    }

    .coming-soon-arc {
        width: 43px;
    }

    .OriginalContainer {
        margin: -287px 0 464px 0px;
    }



    .OriginalContainer div:first-child img,
    .XceleratorContainer div:first-child img,
    .IgnitionContainer div:first-child img {
        width: 43px;
        height: 14px;
    }



    .IgnitionContainer div:first-child img {
        margin-left: 48px;

    }

    .membership-content {
        margin: 5px 0;
    }

    .membership-container-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .membership-btn {
        width: 182px;
        margin-top: 24px;
    }

    .popup {
        max-width: 100%;
    }

    .close-btn {
        margin-right: 46px;

    }




}


@media screen and (min-width:2000px) {

    .membership-container {

        justify-content: center;
    }

}