.LandingPage {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* pointer-events: none; */
}

.backgroundImage {
    position: absolute;
    /* pointer-events: none; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('../assets/LandongPage.svg'); */
    background-image: url('../assets/Landing-page-desk+.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.5s ease-in-out;
    z-index: 5;
}

/* .LandingPage:hover .backgroundImage,
.LandingPage.scroll .backgroundImage
 {
 
    transform: scale(0.8);

  
}


.LandingPage.scroll .backgroundImage,
.LandingPage.scroll .backgroundImage {

    background-size: calc(100% - 34px) calc(100% - 44px);
    top: 0;
} */



/* scrollbar */
/* 
.scrollbar-container{
    width: 100%;
    height: 100vh;
    margin-top: 630px;
    margin-left: 40px;

}

.chevron{
    position: absolute;
    width: 28px;
    height: 26px;
    opacity: 0;
    transform: scale(0.3);
    animation: move-chevron 3s ease-out infinite ;
}

.chevron:first-child{
    animation: move-chevron 3s ease-out 1s infinite ;
}

.chevron:first-child(2){
    animation: move-chevron 3s ease-out 2s infinite ;
}

.chevron:before, .chevron:after{
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background:  #fff;
}

.chevron:before{
    left: 0;
    transform: skew(30deg);
}

.chevron:after{
    right: 0;
    width: 50%;
    transform: skew(-30deg);
}

@keyframes move-chevron {

    25%{
        opacity: 1;
    }
    33.3%{
        opacity: 1;
        transform: translateY(2.28rem);
    }
    66.6%{
        opacity: 1;
        transform: translateY(3.12rem);

    }
    100%{
        opacity: 0;
        transform: translateY(4.8rem) scale(0.5);

    }
    
} */


.scrollbar-container {

        width: 100%;
        height: 100vh;
        margin-top: -51px;
        position: relative;
        display: flex;
        align-items: end;
        justify-content: center;

}

.arrow-scroll {
    position: relative;
    height: 4em;
    cursor: pointer;
}

.arrow {
    border: solid #fff;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 12px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.arrow:nth-child(2) {
    animation: arrow1 1.5s ease-in-out infinite;
}

.arrow:nth-child(3) {
    animation: arrow2 1.5s ease-in-out infinite;
}

@keyframes scroll {

    0% {
        bottom: 80%;
        opacity: 1;
    }

    100% {
        opacity: 1;
        bottom: 20%;
    }


}

@keyframes arrow1 {

    100% {
        opacity: 0;
        top: 100%;
    }


}

@keyframes arrow2 {

    100% {
        opacity: 0;
        top: 50%;
    }


}




@media screen and (max-width: 478px) {

    .backgroundImage {
     
        background-image: url('../assets/Landing-page-mobile.svg');
     
    }
}

@media screen and (min-width: 478px) and (max-width:768px) {

    .backgroundImage {
     
        background-image: url('../assets/Landing-page-tab.svg');
     
    }
}

@media screen and (min-width: 768px) and (max-width:1024px) {

    .backgroundImage {
     
        background-image: url('../assets/Landing-page-lap.svg');
     
    }
}

@media screen and (min-width: 1024px) and (max-width:1439px) {

    .backgroundImage {
     
        background-image: url('../assets/Landing-page-desk.svg');
     
    }
}

@media screen and (min-width: 1024px) and (max-width:1439px) {

    .backgroundImage {
     
        background-image: url('../assets/Landing-page-desk.svg');
     
    }
}