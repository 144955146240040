.custom-payment-container{

    margin: 195px 80px 62px 140px;

}

.payment-header {
    display: flex;
    color: #CCCCCC;
    gap: 12px;
}

.payment-header div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
}

.payment-container{
    display: flex;
    gap: 42px;
    margin-top: 56px;
    color: #666666;

}

.payment-left, .payment-right{
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 24px 36px;
}

.payment-left{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media screen and (max-width:1024px) {

    .custom-payment-container{

        margin: 155px 24px 62px 24px;
    
    }

    .payment-container{
       flex-direction: column;
       font-size: 16px;
    }

    .payment-left, .payment-right{
        width: auto;
        border: none;
        padding: 0;
    }

    .paymetDate{
        flex-direction: column;
    }
    
}