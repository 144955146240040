/* .button {
    padding: 18px 21px;
    border: none;
    background: transparent;
    color: #fff;
    position: relative;
    font-family: "Outfit" !important;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: var(--btn-height, 50px);
    width: var(--btn-width, 200px);
    text-transform: uppercase;
}


.button::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5rem;
    height: 2rem;
    background-color: var(--btn-bg-color);
    transform: translate(-50%, -50%);
    animation: button-animation 5s linear infinite;
    filter: blur(12px);
    z-index: 0;

}

.button::after {
    content: attr(data-value);
    position: absolute;
    top: 2%;
    left: 1%;
    width: 98%;
    height: 96%;
    background-color: #16171B;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
} */


/* @keyframes button-animation {
    0% {
        left: 0;
        top: 0;
    }

    35% {
        left: 100%;
        top: 0;
    }

    50% {
        left: 100%;
        top: 100%;
    }

    85% {
        left: 0%;
        top: 100%;
    }
}

 */



.button:hover::after {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.button-disabled {
    background-color: grey;
    cursor: not-allowed;
    opacity: 0.2;
    color: #4a4848;
}

.button-wrapper {
    position: relative;
    padding: 0;
    background: transparent;
    width: fit-content;
    border: 1px solid rgba(47, 51, 55, 1);
}



@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
}

@property --gradX {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
}

@property --gradY {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
}


:root {
    --d: 2500ms;
    --angle: 50deg;
    --gradX: 100%;
    --gradY: 50%;
    --c1: rgba(255, 255, 255, 0);
    --c2: rgba(9, 9, 9, 0);
}




.button {
    border-image: conic-gradient(from var(--angle), var(--c2), var(--btn-bg-color1) 0.1turn, var(--btn-bg-color2) 0.15turn, var(--c2) 0.25turn) 5;

    /* border-image: conic-gradient(from var(--angle), var(--c2), rgb(244 247 247) 0.1turn, rgb(245 250 251) 0.15turn, var(--c2) 0.25turn) 5; */
    animation: borderRotate var(--d) linear infinite forwards;
    background: transparent;
    text-transform: uppercase;
    font-size: 14px;
    padding: 8px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}

#gradient-text {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 8.85%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


@keyframes borderRotate {
    100% {
        --angle: 420deg;
    }
}