.HomeSections {
    height: 100vh;
    overflow: scroll;
}

.scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    height:100vh;

}

.main-container {
    height: 100vh; 
    overflow-y: auto; 
    overflow-x: hidden;

}

section{
    /* height: 100vh; */
}

#our-events,
#our-plans,
#portfolio,
#contact-us
{
    padding-top: 123px;
}

