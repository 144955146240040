/* Event Display */

.event-background {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background-image: url('../../assets/event-display-bg.svg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    background-attachment: fixed;
}

.event-display-container {
    padding-top: 124px;
    padding-left: 80px;

}

.event-display-div-1 {
    color: #CCCCCC;
    font-size: 14px;

}

.event-display-div-2 {
    text-transform: uppercase;
    color: #fff;
    font-size: 64px;
    font-family: "BuiltTitling";
    margin: 24px 0 24px 0;
    font-weight: 600;
    letter-spacing: 2.2px;
    display: block;
    position: relative;
    overflow: hidden;
}

.event-display-div-2.exit {
    animation: exitAnimation 0.5s forwards;
}

.event-display-div-2.enter {
    animation: enterAnimation 0.5s forwards;
}

@keyframes exitAnimation {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-10%);
        opacity: 0;
    }
}

@keyframes enterAnimation {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


.event-display-div-3,
.event-display-div-4 {
    color: #999999;
    font-size: 18px;
    line-height: 30px;
    display: block;
    position: relative;
    overflow: hidden;
}


.event-display-div-3.exit {
    animation: exitAnimation 0.5s forwards;
}

.event-display-div-3.enter {
    animation: enterAnimation 0.5s forwards;
}

.event-display-div-4.exit {
    animation: exitAnimation 0.5s forwards;
}

.event-display-div-4.enter {
    animation: enterAnimation 0.5s forwards;
}

.disabled-event {
    pointer-events: none;
}


.event-register-btn {
    margin: 36px 0 36px 0;
}

.event-listing {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    margin-left: auto;
}

.event-listing-images {
    display: flex;
    gap: 23px;
    overflow: scroll;
    width: 100%;

}

.event-listing-images div {
    /* width: 308px;
    height: 200px; */
    cursor: pointer;
}



.event-background {
    transition: opacity 0.5s;
}

.event-background.transitioning {
    opacity: 0;
}

.event-img-name {
    text-transform: uppercase;
    color: #fff;
    font-size: 24px;
    font-family: "BuiltTitling";
    letter-spacing: 2px;
    background-color: #202020;
    padding: 10px;
    width: fit-content;
    margin-top: -53px;
    /* position: absolute;
    z-index: 1; */

}

.progress-container-event {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 20px;
}

.navigation-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    padding: 10px;
}

.progress-bar {
    position: relative;
    width: 100px;
    height: 8px;
    background: #333;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 10px;
}

.progress {
    height: 100%;
    width: 33%;
    background: #0E7BF8;
    transition: width 0.3s ease;
    border-radius: 4px;
}


/* Event Description */

.event-description-container {
    margin: 145px 46px 55px 85px;
    color: #CCCCCC;
}

.page-back-nav {
    display: flex;
    align-items: center;
    font-size: 14px;
    /* gap: 20px; */
    cursor: pointer;
    color: #CCCCCC;

}

.logoImg {
    width: 321.41px;
    height: 80px;
}


.carImg {
    height: 360px;
    overflow: hidden;
    position: relative;
}
.carImg img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div, cropping if necessary */
    position: absolute;
    top: 0;
    left: 0;
}

.page-back-nav span {
    margin-left: 10px;
}

.event-Container {
    height: 670px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.leftCon {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 5%;

}

.rightCon {
    width: 50%;
    height: 507px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-family: "Outfit";
    padding: 24px 24px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-direction: column;
    overflow: scroll;
}

.event-register-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.eventPrice {
    font-family: "Space Grotesk";
    font-size: 16px;
    color: #fff;
    margin-bottom: 25px;
    margin-left: 10px;
}

.rightCon .body1 {
    color: #CCCCCC;
    font-family: "Space Grotesk";
}

.endline3 {

    border: 0.5px solid rgba(255, 255, 255, 0.1);
    margin: 26px 0;
}

.rightCon .body2 {
    color: #666666;
}

.eventPlace {
    justify-content: space-between;
}

.spiltCon1 {
    display: flex;
    justify-content: center;
    gap: 10%;
    margin-top: 3%;


}

.spiltCon1 div {
    background-color: rgba(255, 255, 255, 0.02);
    padding: 10.69px;
    width: 100%;
    text-align: center;
}

.see-more {
    color: #0E7BF8;
}

.termsinEvent {
    color: #CCCCCC;

}

.termsEvent {
    color: #666666;
    line-height: 155%;
}

.termsEvent li {
    text-align: justify;

}

.stickyFooter {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-around;
    background-color: #0C0C0C;
    align-items: center;
    position: fixed;
    margin-top: 5%;
    bottom: 0;
    left: 0;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;

}

.eventPriceFooter {
    font-family: "Space Grotesk";
    font-size: 16px;
    color: #fff;
    text-align: center;

}




/* Registration Form */

.event-count {
    display: flex;
    gap: 56px;
    color: #fff;

}

.selector-Container {
    margin-top: 24px;
    border: 0.5px solid #363636;
    border-radius: 2px;
    padding: 15px;


}

.selector-Container button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;

}

.selector-Container input {

    background: transparent;
    border: none;
    color: #fff;
    text-align: center;
    outline: none;

}

.event-form-container {
    margin: 140px 75px 75px 85px;
}

.Event-ticket-component {
    margin: 140px 75px 75px 85px;
    color: #999999;
}

.ticket-container {
    display: flex;
    gap: 125px;
    margin-top: 45px;
}

.ticket-query {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.ticket-event-name {
    text-transform: uppercase;
    color: #fff;
    font-size: 32px;
    font-family: "BuiltTitling";
    font-weight: 600;
    letter-spacing: 2.2px;
    margin-bottom: 5px;
}

.ticket-component {
    margin: 42px 0;
}

.ticket-right-event {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin-left: 20px;
}

.ticket-blue-event {
    width: 400px;
    height: 236px;
    background-image: url('../../assets/ticket-mask.svg');
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.ticket-header-event {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 80%;
    gap: 85px;
    margin-top: 10px;
}

.ticket-id-event {
    font-size: 16px;
    font-weight: bold;
    font-family: "Space Grotesk";
    margin-top: 13px;
    margin-bottom: 13.22px;
}

.ticket-left-text-event {
    font-size: 10px;
    line-height: 1.5;
}

.ticket-component .ticket-header-event img:first-child {
    width: 56px;
}

.ticket-component .ticket-header-event img:nth-child(2) {
    width: 37px;
    height: 37px;
}

.ticket-left-event {
    width: 14%;
    padding: 15px 20px 20px 10px;
    writing-mode: vertical-lr;
    text-align: start;
}

.query-div {
    font-size: 16px;
    color: #fff;
    margin: 10px 0;
}

.event-ticket-btn {
    margin-top: 20px;
}

/*  */


@media screen and (max-width:1024px) {

    /* Display */

    .event-display-container {
        padding-left: 24px;
    }

    .progress-container-event {
        justify-content: center;
    }

    .event-display-div-2 {
        font-size: 36px;
    }

    .event-display-div-3,
    .event-display-div-4 {
        font-size: 14px;
    }

    .event-listing {
        width: 100%;
        align-items: center;
    }

    /* description */

    .event-description-container {
        margin: 145px 24px 55px 24px;
    }

    .event-Container {
        display: block;
    }

    .logoImg {
        width: 150px;
    }


    .stickyFooter {
        visibility: visible;
    }

    .event-register-container{
        visibility: hidden;

    }

    .leftCon {
        width: 100%;
     
    
    }

    .rightCon {
        width: auto;
    }

    /* registration form */

    .event-form-container {
        margin: 140px 24px;
    }


    .xceleratorm-form-left {
        width: auto;
        padding: 0;
        border: none;

    }

    .xceleratorm-form-right {
        width: auto;
        margin-top: 48px;
        padding: 0;
        border: none;

    }

    .Checkout-terms {
        display: block;

    }

    .xr-btn {
        margin-top: 24px;
    }

    .xceleratorm-form-right img:first-child {
        width: 119px;
        height: 42px;
    }

    /* ticket */

    .Event-ticket-component {
        margin: 140px 24px 75px 24px;
    }

    .ticket-container {
        flex-direction: column-reverse;
        gap: 42px;
    }

    .ticket-container-left img:first-child {

        display: none;
    }

    .ticket-query {
        flex-direction: column;
        gap: 16px;
    }

    .query-div {

        margin: 16px 0;
    }

    .ticket-component .ticket-blue-event {

        width: 316px;
    }

    .ticket-component .ticket-header-event img:first-child {
        width: 63px;
        height: 30px;
    }

    .ticket-component .ticket-header-event img:nth-child(2) {
        width: 45px;
    }

    .ticket-component .ticket-header-logo img:first-child {
        width: 43px;
        height: 20px;
    }

    .ticket-component .ticket-header-logo img:nth-child(2) {
        width: 25px;
    }

    .ticket-component .ticket-header-logo {
        height: 160px;
    }

    .ticket-component .ticket-right-event {

        height: 155px;
    }

}