.progressbar{
    display: flex;
    flex-direction: column;
    gap: 85px;
    width: 24px;
    z-index: 2;
    align-items: center;
    margin-top: 80px;


}

.progressbar-container{
    display: flex;
    margin-left: 36px;
    align-items: center;
    height: 100vh;
    position: fixed;

}

.progressbar img{
    cursor: pointer;
}

.slider{
    z-index: 1;

}


@media  screen and (max-width:1023px) {

    .progressbar-container{
        visibility: hidden;
    }

    
}

.home{
    background-color: rgba(16, 16, 18, 1);
    padding: 4px 8px;
    transform: rotate(270deg);
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    text-transform: uppercase;
    width: fit-content;
    height: fit-content;
    text-align: center;
    width: 74px;
    cursor: pointer;

}

.slider {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 6px;
    background-color: rgba(16, 16, 18, 1);
    height: 88%;
    z-index: 0;
}

.slider-fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    transition: height 0.3s, background 0.3s;
}